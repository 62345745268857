
.cssBulletActive_c5om6gd{background:#BC39E5;opacity:1;}
.cssBullet_c8b8w7m{background:#DEDEDE;opacity:1;width:5px;height:5px;min-height:5px;min-width:5px;border-radius:50px;display:inline-block;margin:2px;-webkit-transition:all 0.2s ease-in-out;transition:all 0.2s ease-in-out;}.cssBullet_c8b8w7m.cssBulletActive_c5om6gd{background:#BC39E5;}





.cssCustomSwiper_c14xnlxl.cssNavIsDisabled_c11bck6e .cssNavNext_cy1d490,.cssCustomSwiper_c14xnlxl.cssNavIsDisabled_c11bck6e .cssNavPrev_cf3h2m4{display:none !important;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]{width:auto;padding-right:50px;padding-left:50px;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]:before,.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]:after{position:absolute;content:"";top:0;bottom:0;z-index:2;width:50px;height:100%;background:#FFFFFF;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]:before{left:0;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]:after{right:0;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal] .ButtonSliderArrow_blx2ofu{top:50%;-webkit-transform:translateY(-50%);-ms-transform:translateY(-50%);transform:translateY(-50%);margin:0;z-index:3;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal] .ButtonSliderArrow_blx2ofu:first-of-type{left:0;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal] .ButtonSliderArrow_blx2ofu:last-of-type{right:0;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal] .ButtonSliderArrow_blx2ofu .cssIcon_cc7xf1o{fill:#BC39E5;}@media (max-width:992px){.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]{padding-left:0;padding-right:0;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]:before,.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal]:after{display:none;}}@media (max-width:768px){.cssCustomSwiper_c14xnlxl[data-placement-arrow=horizontal] .ButtonSliderArrow_blx2ofu{display:none;}}.cssCustomSwiper_c14xnlxl[data-placement-arrow=vertical]{padding-top:50px;margin-top:-50px;}.cssCustomSwiper_c14xnlxl[data-placement-arrow=vertical] .ButtonSliderArrow_blx2ofu{top:0;}.cssCustomSwiper_c14xnlxl.cssIsAbsoluteNav_c1vr898l{padding-left:0;padding-right:0;}.cssCustomSwiper_c14xnlxl.cssIsAbsoluteNav_c1vr898l:before,.cssCustomSwiper_c14xnlxl.cssIsAbsoluteNav_c1vr898l:after{display:none;content:none;}
.cssPaginationSequence_c18q0xxk,.cssCustomSwiper_c14xnlxl .cssPaginationSequence_c18q0xxk{width:100%;height:100%;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:row;-ms-flex-direction:row;flex-direction:row;position:absolute;top:0;z-index:2;}.cssPaginationSequence_c18q0xxk .StyledSequenceItem_s1e59qii,.cssCustomSwiper_c14xnlxl .cssPaginationSequence_c18q0xxk .StyledSequenceItem_s1e59qii{-webkit-flex:1;-ms-flex:1;flex:1;}
.StyledSwiperPages_sy9le3m{font-family:montserrat,sans-serif;font-size:.80rem;line-height:150%;font-weight:500;margin:0;color:#7A7A7A;text-align:center;width:100%;padding:0 0 6px 0;}
